<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-4 ">
          <v-form ref="form" lazy-validation>
            <v-card-title>Modification Verset Start Time</v-card-title>
            <v-card-text class="mt-4">
              <div v-for="(chunk, index) in versetInfo" :key="index">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      outlined
                      v-model="chunk.sousverstText"
                      :label="`Part ${chunk.part} `"
                      :rules="rules(chunk.sousverstText)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <vue-timepicker
                      hide-clear-button
                      v-model="chunk.startTime"
                      style="margin-top: 18px"
                      format="HH:mm:ss"
                      label="Select a Verse"
                      manual-input
                    ></vue-timepicker>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      class="info"
                      @click="dialogEditVersetStartTime = true"
                      :disabled="isButtonDisabled"
                    >
                      Sauvegarder
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEditVersetStartTime" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Modifier le verset ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1 error"
              text
              @click="dialogEditVersetStartTime = false"
            >
              Annuler
            </v-btn>
            <v-btn color="info darken-1 " text @click="processVerseDataArray()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import { maxLength } from "vuelidate/lib/validators";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
import Constant from "@/utils/constants";
export default {
  components: { VueTimepicker },
  data() {
    return {
      backVersetInfo: [],
      versetInfo: [],
      dialogEditVersetStartTime: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const versetId = to.params.versetId;
    const recitateurId = to.params.recitateurId;
    const verseData = {
      recitateurId: recitateurId,
      listVersetId: [versetId],
    };
    try {
      const response = await fetch(
        Constant.QORANI + "recitateur/getListVersetStartTime",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(verseData),
        }
      );
      const responseData = await response.json();
      next((vm) => {
        vm.versetInfo = responseData.map((chunk) => {
          const totalSeconds = chunk.startTime;
          const formattedTime = vm.selectedTimeFormatted(totalSeconds);
          chunk.startTime = {
            HH: formattedTime.HH,
            mm: formattedTime.mm,
            ss: formattedTime.ss,
          };
          return chunk;
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
      next();
    }
  },
  computed: {
    isButtonDisabled() {
      return this.versetInfo.some((chunk) => chunk.sousverstText.length > 110);
    },
  },
  methods: {
    rules(chunk) {
      return [(value) => maxLength(110)(value) || "Maximum 110 characters"];
    },
    async processVerseDataArray() {
      for (let i = 0; i < this.versetInfo.length; i++) {
        const verseData = this.versetInfo[i];
        delete verseData.part;
        delete verseData.versetRank;
        delete verseData.versetId;
        const updatedSeconds = this.selectedTimeInSeconds(verseData.startTime);
        verseData.recitateurId = Number(this.$route.params.recitateurId);
        verseData.startTimeInSec = updatedSeconds;
        verseData.isTextChanged =
          this.backVersetInfo[i].sousverstText !==
          this.versetInfo[i].sousverstText;
        verseData.sousVersetText = verseData.sousverstText;
        delete verseData.startTime;
        this.versetInfo[i] = verseData;
      }
      await this.UpdateVersetStartTimeToRecitateur();
    },
    async UpdateVersetStartTimeToRecitateur() {
      console.log(this.versetInfo);
      const apiUrl = Constant.QORANI + "recitateur/editVersetStartTime";
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.versetInfo),
        });
        if (response.ok) {
          window.location.reload();
        }
        if (!response.ok) {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    },
    formatTimeSegment(segment) {
      return segment < 10 ? `0${segment}` : segment.toString();
    },
    selectedTimeFormatted(seconde) {
      const hours = Math.floor(seconde / 3600);
      const minutes = Math.floor((seconde % 3600) / 60);
      const seconds = seconde % 60;

      return {
        HH: this.formatTimeSegment(hours),
        mm: this.formatTimeSegment(minutes),
        ss: this.formatTimeSegment(seconds),
      };
    },
    selectedTimeInSeconds(startTime) {
      const timeParts = `${startTime.HH}:${startTime.mm}:${startTime.ss}`.split(
        ":"
      );
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);
      const seconds = Number(timeParts[2]);

      return hours * 3600 + minutes * 60 + seconds;
    },
    async getBackInfo() {
      const versetId = this.$route.params.versetId;
      const recitateurId = this.$route.params.recitateurId;

      const verseData = {
        recitateurId: recitateurId,
        listVersetId: [versetId],
      };
      try {
        const response = await fetch(
          Constant.QORANI + "recitateur/getListVersetStartTime",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(verseData),
          }
        );
        const responseData = await response.json();
        this.backVersetInfo = responseData.map((backChunk) => {
          const totalSeconds = backChunk.startTime;
          const formattedTime = this.selectedTimeFormatted(totalSeconds);
          backChunk.startTime = {
            HH: formattedTime.HH,
            mm: formattedTime.mm,
            ss: formattedTime.ss,
          };
          return backChunk;
        });
      } catch (error) {
        console.error("An error occurred:", error);
        next();
      }
    },
  },
  async created() {
    await this.getBackInfo();
  },
};
</script>
